module controllers {
    export module customs {

        interface IAccountPaymentScope extends ng.IScope {
            customsPartyId?: number,
            accountPaymentId?: number,
            close(): void,           
        }

        export class accountPaymentCtrl {
            
            static $inject = [
                                "$scope", 
                                '$rootScope', 
                                'generalService', 
                                '$q', 
                                'costModelService', 
                                'classificationValueService',
                                'bsLoadingOverlayService', 
                                "$uibModal",
                                "accountPaymentService",
                                "taxTypeService",
                                "bankService",
                                "entityService",
                                'customsPartyService',
                                
                            ];

            selectedTab: number = 0;
            customsPartyId: number;
            accountPaymentId: number;  

            accountPayment: interfaces.customs.IAccountPaymentMaster;

  
            entity:  interfaces.applicationcore.IDropdownModel;
            currencyId:  number;
            currencyCode: string;
            reference1: string;
            reference2: string;
            taxType?:  interfaces.applicationcore.IDropdownModel;
            postingDateFrom?: Date;
            postingDateTo?: Date;
            declarationCountryId: number = 249;

            disableCustomsParty: boolean = true;

            constructor(
                        private $scope: IAccountPaymentScope, 
                        private $rootScope: interfaces.applicationcore.IRootScope, 
                        public generalService: interfaces.applicationcore.IGeneralService,
                        private $q: ng.IQService, 
                        private costModelService: interfaces.costing.ICostModelService,
                        private classificationValueService: interfaces.applicationcore.IClassificationValueService, 
                        private bsLoadingOverlayService,
                        private $uibModal: angular.ui.bootstrap.IModalService,
                        private accountPaymentService: interfaces.customs.IAccountPaymentService,
                        private taxTypeService: services.tariff.taxTypeService,
                        public bankService: services.master.bankService,
                        private entityService: interfaces.applicationcore.IEntityService,
                        private customsPartyService: interfaces.master.ICustomsPartyService) {

                            this.customsPartyId = this.$scope.customsPartyId;

                            if (this.customsPartyId && this.customsPartyId > 0){
                                this.disableCustomsParty = true;
                            }else{
                                this.disableCustomsParty = false;
                            }

                            this.accountPaymentId = this.$scope.accountPaymentId;
                            if (!this.accountPaymentId){
                                this.accountPaymentId = 0;
                            }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ap.update'
                },
                    () => {

                            return this.loadAccountPayment().then(() => {
                                return this.LoadImporterExporterCustomsPartyDropdownList();
                            });
                        
                       
                    });
            }

          
            loadAccountPayment() {
                var defer = this.$q.defer();
              

                this.accountPaymentService.getAccountPayment(this.accountPaymentId).query((result) => {
                    this.accountPayment = result;   
                    this.gvwDetails.data = this.accountPayment.SelectedTransactions;

                       // After setting the data, select the rows based on the 'Selected' property
                       this.apiACCDetailList.grid.modifyRows(this.gvwDetails.data); // Ensure the rows are recognized by the grid

                       this.gvwDetails.data.forEach((row:interfaces.customs.IAccountLineDisplay) => {
                           if (row.Selected) {
                               this.apiACCDetailList.selection.selectRow(row); // Select the row where 'Selected' is true
                           }
                       });

                       this.apiACCDetailList.core.refresh();
                       
                    defer.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defer.resolve([]);
                });

                return defer.promise;
            }

            SaveAccountPayment(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiACCDetailList){
               // Get the selected rows from the grid
                const selectedRows: Array<interfaces.customs.IAccountPaymentDetailDisplay> = this.apiACCDetailList.selection.getSelectedRows();        
                
                 // Set the selected rows to the 'SelectedTransactions' property of your IAccountLine object
                this.accountPayment.SelectedTransactions = selectedRows;
                }

                this.accountPayment.Currency.Id = this.currencyId;

                this.accountPaymentService.save().save(this.accountPayment, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };


            save(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ap.update'
                },
                    () => {
                        return this.SaveAccountPayment().then((data: boolean) => {
                            if (data) {
                                this.$scope.close();
                            }
                        });
                    });
             
            }

            GetAvailableCustomsAccountDetail(){
                
                let filter: interfaces.customs.IAccountPaymentDetailSearchCriteria = {
                    accountPaymentId: this.accountPaymentId,
                    EntityId: this.accountPayment.Entity.Id, 
                    CustomsPartyId: this.customsPartyId,
                    CurrencyId: this.currencyId,
                    Reference1: this.reference1,
                    Reference2: this.reference2,
                    TaxTypeId: this.taxType?.Id,
                    PostingDateFrom: this.postingDateFrom,
                    PostingDateTo: this.postingDateTo
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ap.update'
                },
                    () => {
                        return  this.accountPaymentService.getAvailableCustomsAccountDetail().query(filter, (data: Array<interfaces.customs.IAccountLineDisplay>) => {                                    
                            this.gvwDetails.data = data;

                            // After setting the data, select the rows based on the 'Selected' property
                            this.apiACCDetailList.grid.modifyRows(this.gvwDetails.data); // Ensure the rows are recognized by the grid

                            this.gvwDetails.data.forEach((row:interfaces.customs.IAccountLineDisplay) => {
                                if (row.Selected) {
                                    this.apiACCDetailList.selection.selectRow(row); // Select the row where 'Selected' is true
                                }
                            });

                        }, (errorResponse) => {
                           
                          
                        });
                    });
            }


            apiACCDetailList: uiGrid.IGridApi;
            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiACCDetailList = gridApi;
                const ctrl = this; // Store the controller reference

                // Hook row selection changed event to calculate the sum
                gridApi.selection.on.rowSelectionChanged(this.$scope, (row) => {

                    if (row.isSelected) {
                        row.entity.Selected = true; // Set Selected to true when selected
                        if (row.entity.AllocatedAmount === 0){
                            row.entity.AllocatedAmount = row.entity.AmountAvailableToAllocate; // Set AllocatedAmount equal to AvailableAmount                        
                            }
                    } else {
                        row.entity.AllocatedAmount = 0; // Set AllocatedAmount to 0 when deselected
                    }

                    ctrl.calculateSelectedAmount(); // Use the stored reference instead of "this"
                });

                // Hook batch selection changed event to calculate the sum when multiple rows are selected at once
                gridApi.selection.on.rowSelectionChangedBatch(this.$scope, (rows) => {

                    rows.forEach(row => {
                        if (row.isSelected) {
                            row.entity.Selected = true; // Set Selected to true when selected
                            if (row.entity.AllocatedAmount === 0){
                                row.entity.AllocatedAmount = row.entity.AmountAvailableToAllocate; // Set AllocatedAmount equal to AvailableAmount
                            }
                        } else {
                            row.entity.AllocatedAmount = 0; // Set AllocatedAmount to 0 when deselected
                        }
                    });

                    ctrl.calculateSelectedAmount(); // Use the stored reference instead of "this"
                });
                    
            }
            
            totalAmount: number;

            calculateSelectedAmount() {
                let selectedRows = this.apiACCDetailList.selection.getSelectedRows();
                let totalAmount = 0;
            
                selectedRows.forEach(row => {
                    if (row.Amount) {
                        totalAmount += parseFloat(row.AllocatedAmount);
                    }
                });
            
                this.totalAmount = totalAmount;
                // You can display the totalAmount in the UI as required
            }

            gvwDetails: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false, 
                enableColumnResizing: true,                            
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                enablePaginationControls: true, // Enable pagination controls
                paginationPageSizes: [10, 25, 50], // Set available page sizes
                paginationPageSize: 10, // Set the default page size
                multiSelect: true, // Allow multi-selection
                enableRowSelection: true, // Enable row selection
                enableSelectAll: true, // Enable "Select All" checkbox
                enableFullRowSelection: false, // Unset full-row selection so the checkbox is separate
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 50,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }, 
                },
                {
                    name: "PostingDate",
                    displayName: "Posting Date",
                    field: "PostingDate",
                    width: 100,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (!searchTerm) return true; // Show all rows if no search term is provided
                            if (!cellValue) return false; // If cell value is empty, return false
                
                            // Convert cellValue to "YYYY/MM/DD" format using moment.js
                            const formattedCellValue = moment(cellValue).format("YYYY/MM/DD");
                
                            // Perform a case-insensitive contains match
                            return formattedCellValue.toLowerCase().includes(searchTerm.toLowerCase());
                        }
                    }
                },
                {
                    name: "Reference1",
                    displayName: "Reference 1",
                    field: "Reference1",
                    width: 120,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                },
                {
                    name: "Reference2",
                    displayName: "Reference 2",
                    field: "Reference2",
                    width: 100,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                }, 
                {
                    name: "Consignment",
                    displayName: "Consignment",
                    field: "Consignment",
                    width: 130,
                    cellTemplate: `<div ng-if="row.entity.consignmentId" class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                        <a style="cursor:pointer" ng-click="grid.appScope.accountPaymentCtrl.ShowConsignment(row.entity.consignmentId, row.entity.IsInbound)"><u>{{MODEL_COL_FIELD}}</u></a>
                                    </div>
                                    <div ng-if="!row.entity.consignmentId" class="ui-grid-cell-contents"></div>`,       
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                },                 
                {
                    name: "ClientName",
                    displayName: "ClientName",
                    field: "ClientName",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                },                                             
                {
                    name: "Type",
                    displayName: "Type",
                    field: "Type",
                    width: 100,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                }, 
                {
                    name: "TaxType",
                    displayName: "Tax Type",
                    field: "TaxType",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                }, 
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    cellFilter: "number: 2",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (!searchTerm) return true; // Show all if search term is empty
                            const term = parseFloat(searchTerm);
                            const value = parseFloat(cellValue);
                            return !isNaN(term) && !isNaN(value) && value.toFixed(2).includes(term.toFixed(2));
                        }
                    }
                }, 
                {
                    name: "AvailableAmount",
                    displayName: "Amount Unallocated",
                    field: "AmountAvailableToAllocate",
                    cellFilter: "number: 2",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (!searchTerm) return true; // Show all if search term is empty
                            const term = parseFloat(searchTerm);
                            const value = parseFloat(cellValue);
                            return !isNaN(term) && !isNaN(value) && value.toFixed(2).includes(term.toFixed(2));
                        }
                    }
                },                 
                {
                  name: "allocatedAmount",
                  displayName: "Allocated Amount",
                  field: "AllocatedAmount",
                  type: "number",
                  cellFilter: "number: 2",
                  cellClass: 'text-right',
                  width: 125,
                  enableCellEdit: true,
                  enableCellEditOnFocus: true,
                  cellEditableCondition: () => { return true },
                  editableCellTemplate: `
                              <form name="inputForm">
                                  <p class="input-group-sm">
                                      <input type="number" name="allocatedAmount" ng-change="grid.appScope.accountPaymentCtrl.calculateSelectedAmount()" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" />
                                  </p>
                              </form>`,
                  filterHeaderTemplate: `
                          <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                              <div class="input-group-sm">
                                  <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                          </div>`,
                  filter: {
                      condition: (searchTerm, cellValue) => {
                          return cellValue >= searchTerm;
                      }
                   }
                  }
                ]
            };

            loadTaxTypes(){
                return this.taxTypeService
                .getDropdownList(this.declarationCountryId)
                .query(
                  () => {},
                  failureData => {
                    this.generalService.displayMessageHandler(<
                      interfaces.applicationcore.IMessageHandler
                    >failureData.data);
                  }
                ).$promise;
            }

            loadBanks() {
                return this.bankService.getDropdownList(this.accountPayment.Entity.Id, "").query({
                }, (resultList) => {
                }).$promise; 
            }

            customsPartyChanged(model: interfaces.applicationcore.IDropdownModel){
                this.customsPartyId = model.Id;
                this.customsPartyService.customsPartyDefaults(this.customsPartyId).query({
                }, (result : interfaces.master.CustomsPartyDefaults) => {
                     this.currencyId = result.customsCurrencyId;
                    this.currencyCode = result.customsCurrencyCode;
                    this.declarationCountryId = result.declarationCountryId;

                    this.entityService.getDropdownList("").query(
                        (resultList) => {
                            this.entity = resultList.find((x) => x.Id === result.entityId);
                            this.accountPayment.Entity = this.entity;
                        }, (failureData) => {
                        }).$promise;
                });
            }

            Next(){
                this.selectedTab = 1;
            }

            Previous(){
                this.selectedTab = 0;
            }

            LoadImporterExporterCustomsPartyDropdownList() {
                return this.customsPartyService.getImporterExporterCustomsPartyDropdownList(this.accountPayment.Entity.Id).query({
                }, (resultList) => {

                    if (this.customsPartyId) {
                        //get the customs party from the list
                        this.accountPayment.CustomsParty = resultList.find((x) => x.Id === this.customsPartyId);
                    }
                    
                    if (this.customsPartyId && this.customsPartyId > 0){

                        return this.customsPartyService.customsPartyDefaults(this.customsPartyId).query({
                        }, (result : interfaces.master.CustomsPartyDefaults) => {

                            this.currencyId = result.customsCurrencyId;
                            this.currencyCode = result.customsCurrencyCode;
                            this.declarationCountryId = result.declarationCountryId;
                            
                            this.entityService.getDropdownList("").query(
                                (resultList) => {
                                    this.entity = resultList.find((x) => x.Id === result.entityId);
                                    this.accountPayment.Entity = this.entity;
                                }, (failureData) => {
                                }).$promise;

                        }
                        ).$promise;
                    }
                }).$promise;
            
            }


            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            ShowConsignment(consignmentId:number,IsInbound:boolean){
                var url = "";
                if (IsInbound){
                    url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
                }
                else{                    
                    url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
                }
        
                window.open(url, '_blank');
            }


            cancel(){
                this.$scope.close();
            }
           
            closeModal() {
                this.$scope.close();
            }
        }

        angular.module("app").controller("accountPaymentCtrl", controllers.customs.accountPaymentCtrl);
    }
}